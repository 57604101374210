import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

// markup
const refundpolicy = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | Refund Policu' />
      <Layout>
        <div className='container'>
          <div className='row '>
            <div className='col-md-12'>
              <section className='section'>
                <div className='section-title'>
                  <h3 className='text-start'>
                    <br /> Refund Policy
                  </h3>
                </div>
                <h5>Refund or return of Donations received for any reason whatsoever is not permitted.</h5>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default refundpolicy;
